import React, { useEffect } from 'react';
import { Card, Text } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import HeaderKSS from '../HeaderKSS';
import FooterKSS from '../FooterKSS';

export function SurveyComplete() {
    const navigate = useNavigate(); // Get the navigate function

    useEffect(() => {
        
        const timeoutId = setTimeout(() => {
            navigate('/survey'); // Navigate to '/survey' after 60 seconds
        }, 600000);

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts before 60 seconds
        };

    }, []);

    return (
        <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '25px' }}>
            <HeaderKSS />
            <h2 style={{color:'orange'}}>Nailed it!</h2>
            <Text style={{ textAlign:'center', color:'GrayText'}}>

            Have a fantastic day and  a Great Sleep tonight!</Text>
            {/* <img src="/survey-images/survey_complete_thank_you.png" alt="Survey Complete - Thank you!" /> */}
            <FooterKSS />
        </Card>
    );
}

export default SurveyComplete;
