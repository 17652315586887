// File: /components/HeaderKSS.js

import React from 'react';

function HeaderKSS() {
return (
    <>
        <a href="/">
            <img src="/survey-images/survey-header.png" alt="Survey Header" style={{ maxWidth: '375px', margin: '25px' }} />
        </a>
    </>
);
}

export default HeaderKSS;