import React, {useEffect} from 'react';
import { Card } from '@aws-amplify/ui-react';
import HeaderKSS from '../HeaderKSS';
import FooterKSS from '../FooterKSS';
import { useNavigate } from 'react-router-dom'; // Import useNavigate 

export function SurveyDoneAlreadyToday() {
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
      const timeoutId = setTimeout(() => {
          navigate('/survey'); // Navigate to '/survey' after 60 seconds
      }, 60000);

      return () => {
          clearTimeout(timeoutId); // Clear the timeout if the component unmounts before 60 seconds
      };
  }, []);
  return (
    <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '25px' }}>
      <HeaderKSS />
      <h2 style={{ margin:'50px', textAlign:'center'}}>You've already completed the survey today!</h2>
      <FooterKSS />
    </Card>
  );
}
export default SurveyDoneAlreadyToday;
