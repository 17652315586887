import React, { useState, useEffect } from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@aws-amplify/ui-react';
import axios from 'axios';
import { parse } from 'json2csv';
import * as XLSX from 'xlsx';

function ResultsTable() {
  const [surveyResults, setSurveyResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchSurveyResults() {
      try {
        const fetchSurveyResultsURL_ROOT = process.env.REACT_APP_APIURLROOT_Survey_API;
        console.log('fetchSurveyResultsURL_ROOT: ',fetchSurveyResultsURL_ROOT); // Check the console output

        const fetchSurveyResultsURL = fetchSurveyResultsURL_ROOT + '/survey-api/surveys';
        console.log('fetchSurveyResultsURL: ', fetchSurveyResultsURL);


         
        console.log('fetchSurveyResultsURL: ', fetchSurveyResultsURL);

        const response = await axios.get(fetchSurveyResultsURL, {
          headers: {
            'x-api-key': 'Ei4oKj1HxT5DstGBoPwFL7mSJjZhyFsl9pNBOIO6' // Replace with your actual API key
          }
        });
        setSurveyResults(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
    fetchSurveyResults();
  }, []);

  const styles = {
    tableHeader: {
      backgroundColor: 'var(--amplify-colors-background-tertiary)',
    },
    firstTableHeader: {
      textAlign: 'right',
    },
    caption: {
      captionSide: 'top',
      textAlign: 'left',
      padding: '1.5rem 0',
      fontSize : '1.5rem',
    },
    tableSummary: {
      color: 'var(--amplify-colors-font-secondary)',
      fontStyle: 'italic',
    },
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}_${minutes}_${seconds}`;
  };

  const processSurveyResults = (results) => {
    return results.map(result => {
      const {
        id,
        first_name,
        last_name,
        email,
        phone,
        user_id,
        submitted_at,
        survey_response_json,
      } = result;
      
      const processedResult = {
        id,
        user_id,
        first_name,
        last_name,
        email,
        phone,
        submitted_at,
        ...survey_response_json,
      };

      // Join array values into comma-separated strings
      Object.keys(processedResult).forEach(key => {
        if (Array.isArray(processedResult[key])) {
          processedResult[key] = processedResult[key].join(', ');
        }
      });

      return processedResult;
    });
  };

  const exportToCSV = () => {
    const processedResults = processSurveyResults(surveyResults);
    const fields = ['id', 'user_id', 'first_name', 'last_name', 'email', 'phone', 'submitted_at', 'nightCaps', 'morningFeel', 'bedroomLight', 'bedroomNoise', 'screensWatched', 'daytimeActivities', 'bedroomTemperature', 'beforeBedActivities'];
    const csv = parse(processedResults, { fields });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const dateTime = getFormattedDateTime();
    const fileName = `survey_results_${dateTime}.csv`;
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportToExcel = () => {
    const processedResults = processSurveyResults(surveyResults);
    const worksheet = XLSX.utils.json_to_sheet(processedResults);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Survey Results');
    const dateTime = getFormattedDateTime();
    const fileName = `survey_results_${dateTime}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  
  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <button onClick={exportToCSV}>Export as CSV</button>
          <button onClick={exportToExcel}>Export as Excel</button>
          <Table 
        highlightOnHover={true}
          variation="striped"
          className="global-styling-table, amplify-table__table"
          
          >
          <caption style={{...styles.caption, ...styles.tableSummary}}>Survey Results received:</caption>
          <TableHead>
            <TableRow className="amplify-table__row">
              <TableCell as="th" style={{...styles.tableHeader, ...styles.firstTableHeader}}>ID</TableCell>
              <TableCell as="th" style={styles.tableHeader}>User ID</TableCell>
               {/* add rows for the other columns: su.first_name, su.last_name, su.email, su.phone, */}

              <TableCell as="th" style={styles.tableHeader}>First Name </TableCell>
              <TableCell as="th" style={styles.tableHeader}>Last Name</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Email</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Phone</TableCell>

              <TableCell as="th" style={styles.tableHeader}>Submitted At</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Night Caps</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Morning Feel</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Bedroom Light</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Bedroom Noise</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Screens Watched</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Daytime Activities</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Bedroom Temperature</TableCell>
              <TableCell as="th" style={styles.tableHeader}>Before Bed Activities</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveyResults.map((result, index) => (
              <TableRow key={index} className="amplify-table__row">
                <TableCell>{result.id}</TableCell>
                <TableCell>{result.user_id}</TableCell>
                <TableCell>{result.first_name}</TableCell>
                <TableCell>{result.last_name}</TableCell>
                <TableCell>{result.email}</TableCell>
                <TableCell>{result.phone}</TableCell>

                <TableCell>{result.submitted_at}</TableCell>
                <TableCell>{result.survey_response_json?.nightCaps}</TableCell>
                <TableCell>{result.survey_response_json?.morningFeel}</TableCell>
                <TableCell>{result.survey_response_json?.bedroomLight}</TableCell>
                <TableCell>{result.survey_response_json?.bedroomNoise}</TableCell>
                <TableCell>{result.survey_response_json?.screensWatched.join(', ')}</TableCell>
                <TableCell>{result.survey_response_json?.daytimeActivities.join(', ')}</TableCell>
                <TableCell>{result.survey_response_json?.bedroomTemperature}</TableCell>
                <TableCell>{result.survey_response_json?.beforeBedActivities.join(', ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </>
      )}
    </div>
  );
}

export default ResultsTable;
