import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool,CognitoUser, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure({
    ...awsconfig,
});

const AutoLogin = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate();
  const sub = new URLSearchParams(window.location.search).get('sub');

    useEffect(() => {
        if (sub) {
      loginWithSub(sub).catch((error) => {
        setError(error.message);
        navigate('/signin?camefrom=autologin');
      });
        }
    }, [sub, navigate]);

  const loginWithSub = async (sub) => {
    console.log('TEST PAYLOAD: ---------------------------------');
    console.log(JSON.stringify({ sub }));
    try {
        //console.log(process.env.NODE_ENV);
        //const response = await fetch('https://xysx7twd77.execute-api.us-east-1.amazonaws.com/dev/generate-token', { //  DEV ENDPOINT
        const response = await fetch('https://q1sq291umf.execute-api.us-east-1.amazonaws.com/prod/generate-token', { //  PROD ENDPOINT
          method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sub }),
      });

      if (response.ok) {
        const result = await response.json();
        //console.log('result:', result);

        const { AccessToken, IdToken, RefreshToken } = result;

        // Manually create the Cognito user session
        const session = new CognitoUserSession({
          IdToken: new CognitoIdToken({ IdToken }),
          AccessToken: new CognitoAccessToken({ AccessToken }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken }),
        });

        const poolData = {
          UserPoolId: awsconfig.aws_user_pools_id,
          ClientId: awsconfig.aws_user_pools_web_client_id,
};
        const userPool = new CognitoUserPool(poolData);

        // Create a user
        const user = new CognitoUser({
          Username: sub,
          Pool: userPool,
        });

        // Attach the session to the user
        user.setSignInUserSession(session);

        //console.log('Login successful!  now... navigate\'/survey?camefrom=autologin\')');
        navigate('/survey?camefrom=autologin');  // Redirect to survey page as (Logged In User)
      } else {
        throw new Error((await response.json()).error);
      }
    } catch (error) {
      //console.log('Error signing in:', error);
      setError(error.message);
    }
  };

  return error ? <div>Error: {error}</div> : <div>Loading...</div>;
};

export default AutoLogin;
