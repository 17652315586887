import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getCurrentUser } from '@aws-amplify/auth';

// File: /components/FooterKSS.js


function FooterKSS() {

    const [isSignedIn, setIsSignedIn] = useState(false);

    // Replace with your logic to determine if the user is signed in using Amplify
    // For example, you can use the Auth module from AWS Amplify
    const checkIsSignedIn = async () => {
        try {
            const user = await getCurrentUser();
            setIsSignedIn(true);
        } catch (error) {
            setIsSignedIn(false);
        }
    };

    // Call the checkIsSignedIn function to determine if the user is signed in

    useEffect(() => {
        checkIsSignedIn();
    }, []);

    return (
        <>
            <footer style={{ paddingTop: '50px', color: 'lightgray', opacity: '0.5', fontSize:14 }}>
                <hr style={{ color: 'lightgray' }}></hr>
                
                <div style={{ textAlign:'center'}}>
                    All rights reserved.&copy; {new Date().getFullYear()} KeystoneSupplements.com
                </div>
                <div style={{ textAlign:'center'}}>
                    {
                        isSignedIn && <a href="/signout" style={{ color:'lightgray'}}>Sign Out</a>
                    }
                </div>
            </footer>
        </>
    );
}


export default FooterKSS;