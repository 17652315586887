const surveyConfig = {
  "showTitle": "false",
  "title": "Sleep Study Survey",
  "logo": "/survey-images/survey-header.png",
  "showProgressBar": "auto",
  "progressBarShowPageNumbers": true,
  "goNextPageAutomatic": false,
  "pages": [
    {
      "name": "page1",
      "elements": [
        {
          "type": "image",
          "name": "progressbar1",
          "imageLink": "/survey-images/progressbar-1.png",
          "imageFit": "cover",
          "imageHeight": 28,
          "imageWidth": 180,
          "css": "centered-image"
        },
        {
          "name": "user_id",
          "type": "hidden",
          "visible": false,
          "minValue": 1,
          "maxValueLength": 50
        },
        {
          "type": "imagepicker",
          "name": "nightCaps",
          "title": "Did you take your NightCaps when you brushed your teeth last night?",
          "isRequired": true,
          "choices": [
            { "value": "Yes" },
            { "value": "No" }
          ]
        }
      ]
    },
    {
      "name": "page2",
      "elements": [
        {
          "type": "image",
          "name": "progressbar2",
          "imageLink": "/survey-images/progressbar-2.png",
          "imageFit": "cover",
          "imageHeight": 28,
          "imageWidth": 180,
          "css": "centered-image"
        },
        {
          "type": "imagepicker",
          "name": "morningFeel",
          "title": "How do you feel this morning?",
          "isRequired": true,
          "choices": [
            { "value": "Fantastic" },
            { "value": "Pretty Good" },
            { "value": "Okay" },
            { "value": "Not Great" },
            { "value": "Exhausted" }
          ]
        }
      ]
    },
    {
      "name": "page3",
      "elements": [
        {
          "type": "image",
          "name": "progressbar3",
          "imageLink": "/survey-images/progressbar-3.png",
          "imageFit": "cover",
          "imageHeight": 28,
          "imageWidth": 180,
          "css": "centered-image"
        },
        {
          "type": "imagepicker",
          "name": "screensWatched",
          "title": "What screens did you watch within 1 hour of going to bed?",
          "multiSelect": true,
          "isRequired": true,
          "choices": [
            { "value": "Phone" },
            { "value": "TV" },
            { "value": "Computer" },
            { "value": "E-reader" },
            { "value": "None" }
          ]
        }
      ]
    },
    {
      "name": "page4",
      "elements": [
        {
          "type": "image",
          "name": "progressbar4",
          "imageLink": "/survey-images/progressbar-4.png",
          "imageFit": "cover",
          "imageHeight": 28,
          "imageWidth": 180,
          "css": "centered-image"
        },
        {
          "type": "imagepicker",
          "name": "bedroomLight",
          "title": "What was your bedroom like when you went to bed?",
          "isRequired": true,
          "choices": [
            { "value": "Light" },
            { "value": "Dark" }
          ]
        },
        {
          "type": "imagepicker",
          "name": "bedroomTemperature",
          "title": "What was your bedroom temperature?",
          "isRequired": true,
          "choices": [
            { "value": "Warm" },
            { "value": "Cool" }
          ]
        },
        {
          "type": "imagepicker",
          "name": "bedroomNoise",
          "title": "What was the noise level in your bedroom?",
          "isRequired": true,
          "choices": [
            { "value": "Loud" },
            { "value": "Quiet" }
          ]
        }
      ]
    },
    {
      "name": "page5",
      "elements": [
        {
          "type": "image",
          "name": "progressbar5",
          "imageLink": "/survey-images/progressbar-5.png",
          "imageFit": "cover",
          "imageHeight": 28,
          "imageWidth": 180,
          "css": "centered-image"
        },
        {
          "type": "imagepicker",
          "name": "beforeBedActivities",
          "multiSelect": true,
          "isRequired": true,
          "title": "Did you have / do any of the following within 2 hours of bed?",
          "choices": [
            { "value": "Smoke" },
            { "value": "Caffeine" },
            { "value": "Alcohol" },
            { "value": "Big Meal" },
            { "value": "Exercise" },
            { "value": "None" }
          ]
        }
      ]
    },
    {
      "name": "page6",
      "elements": [
        {
          "type": "image",
          "name": "progressbar6",
          "imageLink": "/survey-images/progressbar-6.png",
          "imageFit": "cover",
          "imageHeight": 28,
          "imageWidth": 180,
          "css": "centered-image"
        },
        {
          "type": "imagepicker",
          "name": "daytimeActivities",
          "title": "Did you do any of these activities during the DAYTIME?",
          "multiSelect": true,
          "isRequired": true,
          "choices": [
            { "value": "Morning Sun" },
            { "value": "Walking" },
            { "value": "Running" },
            { "value": "Played a Sport" },
            { "value": "Gym Workout" },
            { "value": "None" }
          ]
        }
      ]
    }
  ]
};

export default surveyConfig;
