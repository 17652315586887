import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // Add missing import statement for useNavigate
import { getCurrentUser } from 'aws-amplify/auth';
import SleepSurvey from './components/SleepSurvey/SleepSurvey';
import SignInPage from './components/SignInPage';
import SignOutPage from './components/SignOutPage';
import AutoConfirmPage from './components/AutoConfirmPage';
import HomePageRouter from './components/HomePageRouter';
import { SurveyDoneAlreadyToday } from './components/SleepSurvey/SurveyDoneAlreadyToday';
import { SurveyComplete } from './components/SleepSurvey/SurveyComplete';
import Admin from './components/Admin/Admin';
import AutoLogin from './components/AutoLogin';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';



Amplify.configure(awsExports);



const ProtectedRoute = ({ element, ...rest }) => {
  
  const [userIsInAdminGroup, setUserIsInAdminGroup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkUserGroup();
  }, []);

  const checkUserGroup = async () => {
    const user = await getCurrentUser();
    const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
    setUserIsInAdminGroup(groups.includes('admin'));
  };

  useEffect(() => {
    if (!userIsInAdminGroup) {
      navigate('/survey');
    }
  }, [userIsInAdminGroup, navigate]);

  return userIsInAdminGroup ? <Route {...rest} element={element} /> : null;
};


function App() {
  return (
    <Router>
      <div>
        <Routes>
         
          <Route path="/survey" element={<SleepSurvey />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signout" element={<SignOutPage />} />
          <Route path="/confirm" element={<AutoConfirmPage />} />
          <Route path="/" element={<HomePageRouter />} />

          <Route path="/surveydonealreadytoday" element={<SurveyDoneAlreadyToday />} />
          <Route path="/surveycomplete" element={<SurveyComplete />} />
          <Route path="/autologin/" element={<AutoLogin />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;