import React, { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsExports from '../../aws-exports';
import ResultsTable from '../dashboard/ResultsTable';

Amplify.configure(awsExports);

const Admin = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate(); // Store the useNavigate hook in a variable

    // Check if the user is in the admin group
    const checkAdminGroup = async () => {
        try {
            const { tokens } = await fetchAuthSession();
            const groups = tokens.accessToken.payload["cognito:groups"];
            //console.log("user belongs to following groups: " + groups);

            if (groups && groups.includes("admin")) {
                setIsAdmin(true);
                //console.log("user is awesome admin");
            } else {
                //console.log("user is not an admin");
                setIsAdmin(false);
            }
        } catch (error) {
            //console.log('Error checking admin group:', error);
            setIsAdmin(false);
        } finally {
            setLoading(false); // Set loading to false after the check is complete
        }
    };

    // Call the checkAdminGroup function when the component mounts
    useEffect(() => {
        checkAdminGroup();
    }, []);

    // Call navigate() when isAdmin changes
    useEffect(() => {
        if (!loading && !isAdmin) {
            navigate('/'); // Redirect to home or any other page if not admin
        }
    }, [isAdmin, loading, navigate]);

    // Render the component only if the user is in the admin group
    if (loading) {
        return <div>checking credentials...</div>; // Show a loading message or spinner
    }

    return (
        <div>
            {isAdmin ? (
                <div>
                    <h1>Survery Admin Dashboard:</h1>
                    {/* Add your admin-specific content here */}

                    <ResultsTable />    
                </div>
            ) : (
                <div>
                    <div>checking credentials...</div>; 
                    {/* Add your access denied message or redirect logic here */}
                </div>
            )}
        </div>
    );
};

export default Admin;
