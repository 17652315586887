// create a signout component to handle the signout process

import React, { useEffect } from 'react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

export default function SignOutPage() {
    const navigate = useNavigate();
  
    // Sign out the user when the component mounts
    useEffect(() => {
      const signOutUser = async () => {
        try {
          await signOut();
          navigate('/');
        } catch (error) {
          console.error('Error signing out:', error);
        }
      };
  
      signOutUser();
    }, [navigate]);
    
    return <div>Signing out...</div>;
    }

// The SignOutPage component uses the Auth.signOut method to sign out the user and then redirects the user to the home page.