const imageConfig = {
  "headers": {
    "header1": "/survey-images/survey-header.png",
    "header2": "/survey-images/survey-header.png",
    "header3": "/survey-images/survey-header.png",
    "header4": "/survey-images/survey-header.png",
    "header5": "/survey-images/survey-header.png",
    "header6": "/survey-images/survey-header.png"
  },
  "choices": {
    "nightcaps": {
      "yes": "/survey-images/yes_image.png",
      "yesOn": "/survey-images/yes_image_on.png",
      "no": "/survey-images/no_image.png",
      "noOn": "/survey-images/no_image_on.png"
    },
    "morningfeel": {
      "fantastic": "/survey-images/fantastic_image.png",
      "fantasticOn": "/survey-images/fantastic_image_on.png",
      "prettygood": "/survey-images/pretty_good_image.png",
      "prettygoodOn": "/survey-images/pretty_good_image_on.png",
      "okay": "/survey-images/okay_image.png",
      "okayOn": "/survey-images/okay_image_on.png",
      "notgreat": "/survey-images/not_great_image.png",
      "notgreatOn": "/survey-images/not_great_image_on.png",
      "exhausted": "/survey-images/exhausted_image.png",
      "exhaustedOn": "/survey-images/exhausted_image_on.png"
    },
    "screenswatched": {
      "phone": "/survey-images/phone_image.png",
      "phoneOn": "/survey-images/phone_image_on.png",
      "tv": "/survey-images/tv_image.png",
      "tvOn": "/survey-images/tv_image_on.png",
      "computer": "/survey-images/computer_image.png",
      "computerOn": "/survey-images/computer_image_on.png",
      "ereader": "/survey-images/ereader_image.png",
      "ereaderOn": "/survey-images/ereader_image_on.png",
      "none": "/survey-images/screens_none_image.png",
      "noneOn": "/survey-images/screens_none_image_on.png"
    },
    "bedroomlight": {
      "light": "/survey-images/light_image.png",
      "lightOn": "/survey-images/light_image_on.png",
      "dark": "/survey-images/dark_image.png",
      "darkOn": "/survey-images/dark_image_on.png"
    },
    "bedroomtemperature": {
      "warm": "/survey-images/warm_image.png",
      "warmOn": "/survey-images/warm_image_on.png",
      "cool": "/survey-images/cool_image.png",
      "coolOn": "/survey-images/cool_image_on.png"
    },
    "bedroomnoise": {
      "loud": "/survey-images/loud_image.png",
      "loudOn": "/survey-images/loud_image_on.png",
      "quiet": "/survey-images/quiet_image.png",
      "quietOn": "/survey-images/quiet_image_on.png"
    },
    "beforebedactivities": {
      "smoke": "/survey-images/smoke_image.png",
      "smokeOn": "/survey-images/smoke_image_on.png",
      "caffeine": "/survey-images/caffeine_image.png",
      "caffeineOn": "/survey-images/caffeine_image_on.png",
      "alcohol": "/survey-images/alcohol_image.png",
      "alcoholOn": "/survey-images/alcohol_image_on.png",
      "bigmeal": "/survey-images/big_meal_image.png",
      "bigmealOn": "/survey-images/big_meal_image_on.png",
      "exercise": "/survey-images/exercise_image.png",
      "exerciseOn": "/survey-images/exercise_image_on.png",
      "none": "/survey-images/bed_none_image.png",
      "noneOn": "/survey-images/bed_none_image_on.png"
    },
    "daytimeactivities": {
      "morningsun": "/survey-images/morning_sun_image.png",
      "morningsunOn": "/survey-images/morning_sun_image_on.png",
      "walking": "/survey-images/walking_image.png",
      "walkingOn": "/survey-images/walking_image_on.png",
      "running": "/survey-images/running_image.png",
      "runningOn": "/survey-images/running_image_on.png",
      "playedasport": "/survey-images/played_a_sport_image.png",
      "playedasportOn": "/survey-images/played_a_sport_image_on.png",
      "gymworkout": "/survey-images/gym_workout_image.png",
      "gymworkoutOn": "/survey-images/gym_workout_image_on.png",
      "none": "/survey-images/day_none_image.png",
      "noneOn": "/survey-images/day_none_image_on.png"
    }
  }
};

export default imageConfig;
