import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';



const HomePageRouter = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            try {
                const { username, userId, signInDetails } = await getCurrentUser();
                console.log("username", username);
                console.log("user id", userId);
                console.log("sign-in details", signInDetails);
                //console.log("this is where we would navigate to the survey page (homepagerouter)");
                navigate('/survey');
            } catch (error) {
                console.log('Not signed in:', error);
                console.log("USER NOT SIGNED IN - navigate to the sign-in page (homepagerouter.js");

                navigate('/signin');
            }
        };

        checkUser();
    }, [navigate]);

    return <div>welcome back...</div>;
}

export default HomePageRouter;


//////////////

