import React, { useEffect, useState } from 'react';
import { Authenticator, useAuthenticator,  SelectField } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { fetchAuthSession,signUp, SignUpInput } from 'aws-amplify/auth';
import awsExports from '../aws-exports';
import timeZoneMap from './timeZoneMap';

import HeaderKSS from './HeaderKSS';
import FooterKSS from './FooterKSS';
Amplify.configure(awsExports);

const fixphone11 = (e) => {
    //console.log(e.target.value);
    
    let phonewas = e.target.value
    
    let phonefixed = phonewas;
    // REMOVE ANY NON DIGITS
    phonefixed = phonefixed.replace(/\D/g, '');
    
    if (phonefixed.startsWith('11')) {
        phonefixed = phonefixed.slice(1);
    }
    if (phonefixed.startsWith('1')) {
        phonefixed = phonefixed.slice(1);
    }
    
    //phonefixed = phonefixed.replace('+11', '+1');
    
    // set the calling object value to the fixed phone number
    e.target.value = phonefixed;

    console.log('phonewas:' , phonewas, '   phonefixed:', phonefixed);
  };

const formFields = {
    signIn: {
    },
    signUp: {
        'phone_number': {
            dialCodeList: ['+1']
            ,
            onBlur: (e) =>
                fixphone11({
                 // want to send up a refeed to this form field
                    target: e.target,
                }),
            onChange: (e) =>
                    fixphone11({
                     // want to send up a refeed to this form field
                        target: e.target,
                    }),
            
        },
        'given_name': {
            label: 'First Name',
            placeholder: 'Enter your first name:',
            isRequired: true,
        },
        'family_name': {
            label: 'Last Name',
            placeholder: 'Enter your last name:',
            isRequired: true,
        },
        'custom:zip_code': {
            label: 'Zip Code',
            placeholder: 'Enter zip (or postal code):',
            isRequired: true,

        },

    },
}


// Move timeZoneMap to an external file


const detectTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Detected time zone:', timeZone);
    return timeZoneMap[timeZone] || 'America/New_York'; // Default to Eastern if not found
};

const SignInPageForm = () => {
    const [timeZone, setTimeZone] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    const navigate = useNavigate();

    // Check if the user is in the admin group
    const checkAdminGroup = async () => {
        try {
            const { tokens } = await fetchAuthSession();
            const groups = tokens.accessToken.payload["cognito:groups"];
            // console.log("user belongs to following groups: " + groups);

            if (groups && groups.includes("admin")) {
               // console.log("user is awesome admin");
                return true;
            } else {
                // console.log("user is not an admin");
                return false;
            }
        } catch (error) {
            console.log('Error checking admin group:', error);
            return false;
        } finally {
            //setLoading(false); // Set loading to false after the check is complete
        }
    };
   

    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    useEffect(() => {
        const detectedTimeZone = detectTimeZone();
        setTimeZone(detectedTimeZone);
    }, []);

    useEffect(async () => {
        if (authStatus === 'authenticated') {
            
            localStorage.setItem('timeZone', timeZone);
           // console.log('User is authenticated');
            //console.log('isAdmin:', isAdmin )
            if (checkAdminGroup()) {
                navigate('/admin');
            } else {
                navigate('/survey');
            }
        }
    }, [authStatus, navigate]);

    useEffect(() => {
        return () => {
          // Add code here to cancel any subscriptions or asynchronous tasks
          console.log('Component is unmounted');
        };
      }, []);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <HeaderKSS />
            </div>
            <Authenticator
                formFields={formFields}
                onAuthStateChange={(authState) => {
                    // Handle authentication state change
                    console.log('Auth state changed:', authState);
                }}
                components={{
                    SignUp: {
                        FormFields() {
                            const { validationErrors } = useAuthenticator();

                            return (
                                <>
                                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                                    <Authenticator.SignUp.FormFields />

                                    {/* Append & require Terms and Conditions field to sign up  */}
                                    <SelectField name="custom:timezone"
                                        label="Your Time Zone"
                                        required placeholder='select your time zone'
                                        value={timeZone}
                                        onChange={(e) => setTimeZone(e.target.value)}>
                                        <option value="America/New_York">Eastern (EST)</option>
                                        <option value="America/Chicago">Central (CST)</option>
                                        <option value="America/Denver">Mountain (MST)</option>
                                        <option value="America/Los_Angeles">Pacific (PST)</option>
                                    </SelectField>

                                </>
                            );
                        },
                    },
                }}
            >
                {({ signOut }) => <button onClick={signOut}>Sign out</button>}

            </Authenticator>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FooterKSS />
            </div>
        </>
    );
}

export default () => (
    <Authenticator.Provider>
        <SignInPageForm />
    </Authenticator.Provider>
);