import React, { useEffect, useState, useRef } from 'react';
import 'survey-core/modern.min.css';
import './customSurvey.css'; // Ensure custom CSS is imported
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import surveyConfig from './surveyConfig';
import imageConfig from './imageConfig';
import axios from 'axios';
import { getCurrentUser } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import awsExports from '../../aws-exports';
import { useNavigate } from 'react-router-dom';
import { remove } from 'aws-amplify/storage';

Amplify.configure(awsExports);

StylesManager.applyTheme("modern");

const normalizeText = (text) => {
  return text.toLowerCase().replace(/[^a-z0-9]/g, '');
};

const SleepSurvey = () => {
  const [surveyJson, setSurveyJson] = useState(null);
  const [user, setUser] = useState(null);
  const [user_id, setuser_id] = useState(null); // State for user_id
  const lastClicked = useRef({});
  const [AlreadyDidSurveyToday, setAlreadyDidSurveyToday] = useState(false);
  const navigate = useNavigate();

  const GetSurveyUser = async () => {
    try {
      const { username } = await getCurrentUser();
      //const surveyuserURL = `https://et8htrlatj.execute-api.us-east-1.amazonaws.com/dev/survey-api/users/${username}`;
      const surveyuserURL = `https://8rq89eo2y3.execute-api.us-east-1.amazonaws.com/prod/survey-api/users/${username}`;
      const response = await axios.get(surveyuserURL, {
        headers: {
          'x-api-key': 'Ei4oKj1HxT5DstGBoPwFL7mSJjZhyFsl9pNBOIO6' // Replace with your actual API key
        }
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        const userData = response.data[0];
        console.log('User data:', userData);
        setUser(userData);
        setuser_id(userData.user_id); // Set user_id in state

        if (userData.AlreadyDidSurveyToday) {
          setAlreadyDidSurveyToday(true);
        } else {
          console.log('userData.AlreadyDidSurveyToday:',userData.AlreadyDidSurveyToday);
          // Replace image keys before setting the survey JSON
          const updatedSurveyConfig = { ...surveyConfig };
          replaceImageKeys(updatedSurveyConfig, imageConfig);
          setSurveyJson(updatedSurveyConfig);
        }
      } else {
        console.error('User data not found in response:', response.data);
      }
    } catch (e) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sub = urlParams.get('sub');
      if (sub) {
        return navigate(`/autologin?sub=${sub}`);
      } else {
        return navigate('/signin');
      }
    }
  };

  useEffect(() => {
    GetSurveyUser();
  }, []);

  useEffect(
    async () => {
      try {
        const isAuthenticated = await getCurrentUser();

        if (!isAuthenticated) {
          // check value of ?sub=
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const sub = urlParams.get('sub');
          if (sub) {
            return navigate(`/autologin?sub=${sub}`);
          } else {
            return navigate('/signin');
          }
        } else {
          // No-op, since we've already fetched the survey data
        }
      } catch (error) {
        // check value of ?sub=
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sub = urlParams.get('sub');
        if (sub) {
          return navigate(`/autologin?sub=${sub}`);
        } else {
          return navigate('/signin');
        }
        // Redirect to login page
        console.error('Error:', error);
      }
    }, []);

  useEffect(() => {
    if (AlreadyDidSurveyToday) {
      navigate('/SurveyDoneAlreadyToday'); // Redirect if the user has already completed the survey today
    }
  }, [AlreadyDidSurveyToday, navigate]);

  const replaceImageKeys = (surveyJson, imageConfig) => {
    surveyJson.pages.forEach((page) => {
      page.elements.forEach((element) => {
        if (element.type === 'imagepicker') {
          if (element.choices) {
            element.choices.forEach((choice) => {
              if (choice.value) {
                const normalizedElementName = normalizeText(element.name);
                const normalizedChoiceValue = normalizeText(choice.value);
                const choiceConfig = imageConfig.choices[normalizedElementName][normalizedChoiceValue];
                if (choiceConfig) {
                  choice.imageLink = choiceConfig;
                  choice.imageLinkOff = choiceConfig;
                  choice.imageLinkOn = choiceConfig.replace('.png', '_on.png');
                }
              }
            });
          }
        }
      });
    });
  };

  if (!surveyJson) {
    return (
      <div style={{ color: 'lightgrey', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>Loading survey...</div>
      </div>
    );
  }

  // Add the custom CSS class to the survey model
  const survey = new Model(surveyJson);
  survey.css = {
    ...survey.css,
    imagepicker: {
      root: 'custom-image-picker',
      item: 'sv-imagepicker__item', // Ensure this is correctly targeted
      label: 'sv-imagepicker__label',
      image: 'sv-imagepicker__image'
    }
  };

  
////////////////////////// /////////////////////////////////////////////////////  ////////////////////

  const handleNoneClicked = (sender, options) => {

    console.log('handleNoneClicked > options.value:', options.value);
    console.log('handleNoneClicked > options.oldValue:', options.oldValue);

    const question = options.question;
    let lastvalue = survey.getQuestionByName(question.name).value;
    if (Array.isArray(lastvalue)) {
      lastvalue = lastvalue[lastvalue.length - 1];
    } else { console.log('lastvalue was not an array'); }

    if (lastvalue === 'None') {
      options.value = ['None'];
    } else {
      // remove None from the array
      if (Array.isArray( options.value)){
      options.value = options.value.filter(val => val !== 'None');
      } 
    }
  }







////// /////////////////////////////////////////////////////  ////////////////////
  const handleValueChangedSwapImagesOnOff = (sender, options) => {
    const question = options.question;
    let lastvalue = survey.getQuestionByName(question.name).value;
    // check if the value is an array
    if (Array.isArray(lastvalue)) {
      lastvalue = lastvalue[lastvalue.length - 1];
    } else { console.log('not an array'); }
   
    console.log('lastvalue:', lastvalue);


    for (const choice of question.choices) {
      if (question.value.includes(choice.value)) {
        choice.imageLink = choice.imageLink.replace('_on.png', '.png').replace('.png', '_on.png');

        // if (choice.value.toLowerCase() === 'none') {

        //   for (const otherChoice of question.choices) {
            
        //     if (otherChoice !== choice) {
        //       console.log('CLICKED NON otherChoice:', otherChoice.value);
        //       //
        //       //question.value = question.value.filter(val => val !== otherChoice.value);
        //       otherChoice.imageLink = otherChoice.imageLink.replace('_on.png', '.png');
        //     }
        //   }

        // } else {
        //   for (const otherChoice of question.choices) {
        //     if (otherChoice !== choice && otherChoice.value.toLowerCase() === 'none') {
        //       console.log('CLICKED OTHER otherChoice:', otherChoice.value);
        //       otherChoice.imageLink = otherChoice.imageLink.replace('_on.png', '.png');
        //     }
        //   }
        // }


      } else {
        choice.imageLink = choice.imageLink.replace('_on.png', '.png');
      }
    }
  };

  
  survey.onValueChanging.add(handleNoneClicked);
  survey.onValueChanged.add(handleValueChangedSwapImagesOnOff);

  const handleSurveyComplete = async (result) => {
    try {
      //const response = await axios.post('https://et8htrlatj.execute-api.us-east-1.amazonaws.com/dev/survey-api/new-survey', { // DEV ENDPOINT
        const response = await axios.post('https://8rq89eo2y3.execute-api.us-east-1.amazonaws.com/prod/survey-api/new-survey', { // PROD ENDPOINT
          user_id: user_id,
        survey_response_json: result.data,
        response_referrer: 'web'
      }, {
        headers: {
          'x-api-key': 'Ei4oKj1HxT5DstGBoPwFL7mSJjZhyFsl9pNBOIO6' // Replace with your actual API key
        }
      });
      console.log('Survey submission successful:', response.data);
      // Redirect to the survey complete page
      navigate('/SurveyComplete');
    } catch (error) {
      console.error('Error during survey submission:', error);
    }
  };

  // Disable the SurveyJS completion screen
  survey.completedHtml = 'saving...';

  return (
    <div style={{ maxWidth: '800px' }}>
      <Survey model={survey} onComplete={handleSurveyComplete} />
    </div>
  );
};

export default SleepSurvey;
