import React, { useState } from 'react';
import { signIn, signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';


export default function AutoConfirmPage() {
return (
    <Authenticator>
    </Authenticator>
  );
}